(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
/*global $, jQuery, alert, console*/

"use strict";

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows() );
    }
};






// $("form#bottomForm").on("submit", function (event) {
//     event.preventDefault();
//     //need validate if Ok:
//
//     // var data_set = {action: 'sent_form', form_data: $( this ).serialize(), form_type: 'help_build_team'};
//
//     $.post(Persona.api_url, $(this).serialize()).done(function () {
//         switch (window.location.origin) {
//
//         }
//     }).fail(function () {
//         alert('Sorry, an error has occurred, please try again!');
//     });
//     return false;
// });


$(document).ready(function () {

    var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy"
    });
    if (lazyLoadInstance) {
        lazyLoadInstance.update();
    }

    $('.fixtoElem').fixTo('.fixtoBody', {
        useNativeSticky: true,
        top: 100
    });

    $('.sidenav').sidenav();
    $('.tabs').tabs();
    $('.tabs-services').tabs();
    $('.modal__video').modal();
    $('.modal_custom').modal();

    $('header').addClass('seen');
    let modalTrainersWasOpen = false;
    let trainerIndex = 0;
    $('.s-trainers__block').on('click', function(){
        trainerIndex = $(this).attr('data-slick-index');
    });
    $('.modal__trainers').modal({
        onOpenEnd: function () {
            if (!modalTrainersWasOpen) {
                slickSliderModalTrainersControl();
            }
            $('.custom-slider__arrows-slider_modal').slick('slickGoTo',parseInt(trainerIndex));
            $('.custom-slider__arrows-slider').each(function () {
                $(this).slick('slickPause');
            })
        },
        onCloseEnd: function () {
            $('.custom-slider__arrows-slider').each(function () {
                $(this).slick('slickPlay');
            })
        }
    });
    $('#formFreeTrialModal').modal({
        onOpenEnd: function () {
            $('.s-process-wrap--modal-trial').addClass('seen')
        },
        onCloseEnd: function () {
            $('.s-process-wrap--modal-trial').removeClass('seen')
        }
    });
    $('#formFreeTrialRoiModal').modal({
        onOpenEnd: function () {
            $('.s-process-wrap--modal-trial').addClass('seen')
        },
        onCloseEnd: function () {
            $('.s-process-wrap--modal-trial').removeClass('seen')
        }
    });

    $('.modal-close').on('click', function (e) {
        e.preventDefault();
    });

    $('a').on('click', function (e) {
        if ($(this).attr('href') == '#' || $(this).attr('href') == '' || $(this).attr('href') == '!#' || $(this).attr('href') == '#!') {
            e.preventDefault();
        }
    });


    $('button[type="submit"]').on('click', function () {
        var validStep = true;

        var flag = false;
        var curForm = $(this).closest('form');

        setTimeout(function () {
            curForm.find('input').each(function () {
                if ($(this).hasClass('invalid')) {
                    validStep = false;
                }
            });

            if (validStep) {
                dataLayer.forEach(function (el) {
                    if (el["event"] == "lead_source_type_c") {
                        flag = true;
                        el["name"] = curForm.find('input[name="lead_source_type_c"]').val();
                    }
                });
                if (!flag) {
                    var name = curForm.find('input[name="lead_source_type_c"]').val();
                    dataLayer.push(
                        {
                            "event": "lead_source_type_c",
                            "name": name
                        }
                    )
                }
            }
        },300);

    });

    addInlineSvg();
    slickSliderControl();
    controlTopMenu();
    controlAnimatedScroll();
    detectSeenElements();
    controlYoutubeVudeo();
    controlHeaderVideo();
    controlPrivacyPolicy();
    controlTabsTable();


    function controlTabsTable() {
        $('.table-desctp-head__btn').on('click', function (e) {
            var activeAttr;
            e.preventDefault();
            $('.table-desctp-head__btn').each(function() {
                $(this).removeClass('active');
            });
            activeAttr = $(this).attr('data-href');
            $(this).addClass('active');

            $('.table-desctp-head__text').each(function () {
                $(this).removeClass('active');
                if ($(this).attr('data-href') == activeAttr) {
                    $(this).addClass('active');
                }
            })
        });


    }

    function controlPrivacyPolicy() {
        // start privacy policy

        try {
            $(".policy-aside-desktop").fixTo('.policy-content', {
                top: 100
            });
        } catch (e) {
        }

        $('.policy-aside-first > .policy-aside-item > .policy-aside-item-wrap > .policy-aside-item-title').click(function (event) {
            event.preventDefault();
            if ($(this).parent().next().hasClass('show')) {
                $(this).next().removeClass('active');
                $(this).removeClass('active');
            } else {
                $(this).next().addClass('active');
                $(this).addClass('active');
            }
        });

        $('.policy-aside-link-more').click(function (event) {
            event.preventDefault();
            if ($(this).parent().next().hasClass('show')) {
                $(this).removeClass('active');
                $(this).prev().removeClass('active');
            } else {
                $(this).addClass('active');
                $(this).prev().addClass('active');
            }
        });
    }



    function controlHeaderVideo() {
        $('.animate-block__video-block').on('click', function () {
            $(this).toggleClass('playing');
            if ($(this).hasClass('playing')) {
                $(this).find('#headerVideo').get(0).play();
            } else {
                $(this).find('#headerVideo').get(0).pause();
            }
        });


        $('.modal__video_header').modal({
            onOpenEnd: function () {
                $('#headerVideo').get(0).play();
            },
            onCloseEnd: function () {
                $('#headerVideo').get(0).pause();
            }
        });

        $('.modal__video_solo-local').modal({
            onOpenEnd: function () {
                $('#videoLocal').get(0).play();
            },
            onCloseEnd: function () {
                $('#videoLocal').get(0).pause();
            }
        });



    }


    function controlYoutubeVudeo() {
        $('.ytb-video').each(function () {
            let urlArray = $(this).attr('data-ytb-video').split("/");
            let idVideo = urlArray[urlArray.length - 1];
            if (idVideo.indexOf("?") != -1) {
                idVideo = idVideo.substring(0, idVideo.indexOf("?"));
            }
            $(this).css('background-image', 'url(https://img.youtube.com/vi/' + idVideo + '/sddefault.jpg)');
        });

        $('.ytb-video__slider').each(function () {
            let urlArray = $(this).attr('data-ytb-video').split("/");
            let idVideo = urlArray[urlArray.length - 1];
            if (idVideo.indexOf("?") != -1) {
                idVideo = idVideo.substring(0, idVideo.indexOf("?"));
            }
            $(this).css('background-image', 'url(https://img.youtube.com/vi/' + idVideo + '/sddefault.jpg)');
        });



        $('.ytb-video').on('click', function () {
            let urlVideo;
            urlVideo = $(this).attr('data-ytb-video');
            $('.modal__video').modal({
                onOpenStart: function() {
                    $('.modal__video-iframe').attr('src', urlVideo);
                },
                onCloseEnd: function () {
                    $('.modal__video-iframe').attr('src','');
                }
            });
        });

        $('.ytb-video__slider').on('click', function () {
            let urlVideo;
            urlVideo = $(this).attr('data-ytb-video');
            $('.modal__video').modal({
                onOpenStart: function() {
                    $('.modal__video-iframe_slider').attr('src', urlVideo);
                },
                onCloseEnd: function () {
                    $('.modal__video-iframe_slider').attr('src','');
                }
            });
        });

        $('#videoModalPopup').modal({
            onOpenEnd: function() {
                $('#videoModalPopup video').get(0).play();
            },
            onCloseEnd: function () {
                $('#videoModalPopup video').get(0).pause();
            }
        });

    }


    controlStepForm();
    function controlStepForm() {
        $('.step-btn_next').on('click', function (e) {
            e.preventDefault();
            var flag = false;
            $(this).closest('.step-block').find('.form-control').each(function () {
                if($(this).hasClass('invalid')) {
                    flag = false;
                    return false;
                } else {
                    flag = true;
                }
            });

            if(flag) {
                $(this).closest('.step-block').removeClass('active').next('.step-block').addClass('active');
                $(this).closest('form').find('.form-control').each(function() {
                    $(this).removeClass('invalid');
                })
                $(this).closest('form').find('.form-error').each(function() {
                    $(this).remove();
                })
            }
        });

        $('.step-btn_back').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.step-block').removeClass('active').prev('.step-block').addClass('active');
        })
    }


    function detectSeenElements() {
        var sections = $('.image_animated_parent, .s-process-wrap');


        $(window).on('scroll', function () {
            var cur_pos = $(this).scrollTop();

            sections.each(function() {
                var top = $(this).offset().top - $( window ).height() + 100;
                var bottom = $(this).offset().top - 100;
                $(this).removeClass('visible');
                if (cur_pos >= top && cur_pos <= bottom) {
                    $(this).addClass('visible');
                    $(this).addClass('seen');
                }
            });
        });
        var cur_pos = $(window).scrollTop();

        sections.each(function() {
            var top = $(this).offset().top - $( window ).height() + 100;
            var bottom = $(this).offset().top - 100;
            $(this).removeClass('visible');
            if (cur_pos >= top && cur_pos <= bottom) {
                $(this).addClass('visible');
                $(this).addClass('seen');
            }

        });
    }

    function controlTopMenu() {
        var scrollHeight = $(window).scrollTop();

        if(scrollHeight  > 0) {
            $('.menu').addClass('not-top');
        } else {
            $('.menu').removeClass('not-top');
        }
        $(window).scroll(function() {
            scrollHeight = $(window).scrollTop();

            if(scrollHeight  > 0) {
                $('.menu').addClass('not-top');
            } else {
                $('.menu').removeClass('not-top');
            }
        });


        $('.menu-link_drop').on('click', function (e) {
            if (!$(this).hasClass('active')) {
                e.preventDefault();
                let curId = $(this).attr('href');
                $('.menu-link_drop').each(function () {
                    $(this).removeClass('active');
                });
                $(this).addClass('active');

                $('.menu__dropdown').each(function () {
                    $(this).removeClass('active');
                });
                $(curId).addClass('active');
                $('.menu').addClass('menu-white');
                $('body').addClass('overflow-hidden');
                console.log()
                $('.menu__dropdown .active').find($('.dropdown__top-wrap')).addClass('ASH')
            } else {
                $('.menu-link_drop').each(function () {
                    $(this).removeClass('active');
                });
                $('.menu__dropdown').each(function () {
                    $(this).removeClass('active');
                });
                $('.menu').removeClass('menu-white');
                $('body').removeClass('overflow-hidden');
            }
        });

        $('.link__close-dropmenu').on('click', function (e) {
            e.preventDefault();
            $('.menu-link_drop').each(function () {
                $(this).removeClass('active');
            });
            $('.menu__dropdown').each(function () {
                $(this).removeClass('active');
            });
            $('.menu').removeClass('menu-white');
            $('body').removeClass('overflow-hidden');
        });

        $(document).click(function(event) {
            var target = $(event.target);
            if(!target.closest('.menu__dropdown').length && !target.hasClass('menu__dropdown') && !target.closest('.menu-link_drop').length && !target.hasClass('menu-link_drop')) {
                $('.menu-link_drop').each(function () {
                    $(this).removeClass('active');
                });
                $('.menu__dropdown').each(function () {
                    $(this).removeClass('active');
                });
                $('.menu').removeClass('menu-white');
                $('body').removeClass('overflow-hidden');
            }
        });

        $('.unclick').on('click', function (e) {
            e.preventDefault();
        })
    }

    function addInlineSvg() {
        $('img.image_svg').each(function(){
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            jQuery.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
                if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('width') + ' ' + $svg.attr('height'))
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });
    }

    controlService3Scroll();
    function controlService3Scroll() {
        $('#serviceSection3 .custom-scroll').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $('#serviceSection3').offset().top + 50
            }, 500);
        });

        $('.s-services-content-tablet .tab').on('click', function () {
            $('.s-services-content-tablet .tab').each(function () {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
        })
    }

    function controlAnimatedScroll() {
        $(document).on('click', 'a[href^="#"]', function (event) {
            if($(this).attr('href') === '#') return;

            if($(this).closest('.nav-wrapper')) return;

            if (!$(this).parent('.tab').length && !$(this).hasClass('modal-trigger') && !$(this).hasClass('modal-close')) {
                event.preventDefault();

                $('html, body').animate({
                    scrollTop: $($.attr(this, 'href')).offset().top - 90
                }, 1000);
            }

        });


        $('.policy-aside-item-title').on('click', function (e) {
            e.preventDefault();
            var linkHref = $(this).attr('href');
            $('html, body').animate({
                scrollTop: $(linkHref).offset().top - 120
            }, 500);
        });
    }

    $('.animated-scroll').on('click', function (e) {
        var linkHref = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $(linkHref).offset().top - 80
        }, 500);
    });




    function slickSliderControl() {
        let servicesSliders = document.querySelectorAll('.s-services__slider-wrap');
        let slickSlider = 0;

        for(let slider of [...servicesSliders]){
            slickSlider++;
            let sliderName = 'slider-' + slickSlider;
            $(slider).addClass(sliderName)[0];

            let currentField = $('.' + sliderName).prev().find('.slider-count__current');
            let amountField = $('.' + sliderName).prev().find('.slider-count__amount');

            let setCurrentSlide = function(event, slick, currentSlide){
                let totalCount = (currentSlide ? currentSlide : 0) + 1;
                let slidesLength = $('.s-services__slider-wrap .s-services__slide');

                if(slick.slideCount){
                    let currentCount = slick.slideCount < 10 ? '0' + slick.slideCount : slick.slideCount;
                    amountField.html(currentCount);
                } else {
                    let currentCount = slidesLength.length < 10 ? '0' + slidesLength.length : slidesLength.length;
                    amountField.html(currentCount);
                }
            };
            $('.' + sliderName).on('init reInit afterChange', setCurrentSlide);

            $('.' + sliderName).slick({
                infinite: true,
                prevArrow: $('.' + sliderName).prev().find('.s-slider-arrows__left'),
                nextArrow: $('.' + sliderName).prev().find('.s-slider-arrows__right'),
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 513,
                        settings: {
                            variableWidth: false,
                        }
                    }
                ]
            });
        }

        // -- end services slider slick init --

        $('.s-brands__content').slick({
            slidesToScroll: 1,
            arrows: false,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 0,
            swipe: false,
            centerMode: true,
            draggable: false,
            speed: 3000,
            variableWidth: true,
            pauseOnHover: false,
            pauseOnFocus: false
        });

        $('.s-why-tabs__result').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            infinite: true,
            fade: true,
            autoplay: true,
            autoplaySpeed: 3000,
            adaptiveHeight: true,
            asNavFor: $('.s-why-tabs__nav'),
        });

        $('.s-why-tabs__nav').slick({
            slidesToShow: 8,
            slidesToScroll: 8,
            arrows: false,
            variableWidth: true,
            asNavFor: $('.s-why-tabs__result'),
            vertical: true,
            infinite: false,
            centerMode: true,
            focusOnSelect: true,
        });


        $('.s-why-tabs__nav .s-why-tab').on('click', function() {
            $('.s-why-tabs__result').slick('slickSetOption', {
                'autoplay': false
            }, true);
        });


        $('.s-why-tabs__result_circle').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            infinite: true,
            fade: true,
            autoplay: false,
            autoplaySpeed: 3000,
            asNavFor: $('.s-why-tabs__nav_circle'),
        });

        $('.s-why-tabs__nav_circle').slick({
            slidesToShow: 8,
            slidesToScroll: 8,
            arrows: false,
            variableWidth: true,
            asNavFor: $('.s-why-tabs__result_circle'),
            vertical: true,
            infinite: false,
            centerMode: true,
            focusOnSelect: true,
        });


        $('.s-header-slider').slick({
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
        });

        $('.s-header-slider-benefits').slick({
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 800,
            fade: true,
        });


        $('.s-package-slider-items').slick({
            arrows: true,
            prevArrow: '<div class="s-package-slider-arrows s-package-slider-arrows__left"><div class="s-package-slider-arrows__left-image s-package-slider-arrows__sticky"></div></div>',
            nextArrow: '<div class="s-package-slider-arrows s-package-slider-arrows__right"><div class="s-package-slider-arrows__right-image s-package-slider-arrows__sticky"></div></div>',
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]

        });

        $('.s-package-slider-select').change(function(){
            let select = $('.s-package-slider-select').val()
            let slider = $( '.s-package-slider-items' );
            if (select === "AI-based temperature screening") {
                slider[0].slick.slickGoTo(parseInt(0));
            } else if (select === "An autonomous UVC disinfection") {
                slider[0].slick.slickGoTo(parseInt(2));
            } else if (select === "Contact tracing") {
                slider[0].slick.slickGoTo(parseInt(5));
            } else if (select === "Masks detection") {
                slider[0].slick.slickGoTo(parseInt(6));
            } else if (select === "The manual cleaning") {
                slider[0].slick.slickGoTo(parseInt(7));
            } else if (select === "A secondary check application") {
                slider[0].slick.slickGoTo(parseInt(8));
            }


        });





        $('.s-why-tabs__nav_circle .s-why-tab').on('click', function() {
            $('.s-why-tabs__result_circle').slick('slickSetOption', {
                'autoplay': false
            }, true);
        });


        let commonArrows = function(event, slick, currentSlide){
            let totalCount = (currentSlide ? currentSlide : 0) + 1;
            let slidesLength = event.currentTarget.querySelectorAll('.slider_block').length;

            let amountField = $(this).closest('.container').find('.slider-count__amount');

            if(slick.slideCount){
                let currentCount = slick.slideCount < 10 ? '0' + slick.slideCount : slick.slideCount;
                amountField.text(currentCount);
            } else {
                let currentCount = slidesLength.length < 10 ? '0' + slidesLength.length : slidesLength.length;
                amountField.text(currentCount);
            }
        };

        $('.s-slider-bg-image__text-slider').on('init reInit afterChange', commonArrows);

        $('.s-slider-bg-image__text-slider').each(function (e) {
            $(this).slick({
                infinite: true,
                prevArrow: $(this).closest('.container').find('.s-slider-arrows__left'),
                nextArrow: $(this).closest('.container').find('.s-slider-arrows__right'),
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false,
                adaptiveHeight: true,
                focusOnSelect: true,
                autoplay: false,
                autoplaySpeed: 3000,
                asNavFor: $(this).closest('.s-slider-bg-image__text-slider-wrap').next('.s-slider-bg-image__image-slider-wrap').find('.s-slider-bg-image__image-slider'),
            });

            $(this).closest('.s-slider-bg-image__text-slider-wrap').next('.s-slider-bg-image__image-slider-wrap').find('.s-slider-bg-image__image-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
                arrows: false,
                asNavFor: $(this),
                infinite: true,
                centerMode: true,
                focusOnSelect: true,
                fade: true,
                cssEase: 'linear'
            });
        })




        $('.custom-slider__arrows-slider').on('init reInit afterChange', commonArrows);




        function controlSlickCustomArrowSlider() {
            if(document.querySelector('.custom-slider__arrows-slider') !== null){
                $('.custom-slider__arrows-slider').each(function () {
                    let numberSlides = +$(this).attr('data-slide-show');
                    let prevArr = $(this).closest('.container').find('.s-slider-arrows__left');
                    let nextArr = $(this).closest('.container').find('.s-slider-arrows__right');
                    if ($(this).hasClass('s-cases__blocks-wrap')) {
                        $(this).slick({
                            infinite: true,
                            prevArrow: prevArr,
                            nextArrow: nextArr,
                            slidesToShow: numberSlides,
                            slidesToScroll: 1,
                            variableWidth: false,
                            focusOnSelect: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            pauseOnHover: true,
                            pauseOnFocus: true,
                            responsive: [
                                {
                                    breakpoint: 992,
                                    settings: {
                                        slidesToShow: 2,
                                    }
                                },
                                {
                                    breakpoint: 601,
                                    settings: {
                                        slidesToShow: 1
                                    }
                                },
                            ]
                        });
                    } else if (numberSlides > 1) {
                        $(this).slick({
                            infinite: true,
                            prevArrow: prevArr,
                            nextArrow: nextArr,
                            slidesToShow: numberSlides,
                            slidesToScroll: 1,
                            variableWidth: false,
                            focusOnSelect: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            pauseOnHover: true,
                            pauseOnFocus: true,
                            responsive: [
                                {
                                    breakpoint: 1400,
                                    settings: {
                                        slidesToShow: 2,
                                    }
                                },
                                {
                                    breakpoint: 600,
                                    settings: {
                                        slidesToShow: 1
                                    }
                                },
                            ]
                        });
                    } else {
                        $(this).slick({
                            infinite: true,
                            prevArrow: prevArr,
                            nextArrow: nextArr,
                            slidesToShow: numberSlides,
                            slidesToScroll: 1,
                            variableWidth: false,
                            focusOnSelect: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            pauseOnHover: true,
                            pauseOnFocus: true,
                            responsive: [
                                {
                                    breakpoint: 600,
                                    settings: {
                                        slidesToShow: 1
                                    }
                                },
                            ]
                        });
                    }

                });

            }
        }
        controlSlickCustomArrowSlider();


        function detectAllSlidesIsShowing() {
            $('.custom-slider__arrows-slider').each(function () {
                let slidesCount = 0;
                let slidesActive = 0;
                $(this).find('.slider_block').each(function () {
                    slidesCount++;
                    if ($(this).hasClass('slick-active')) {
                        slidesActive++
                    }
                })
                if (slidesCount == slidesActive) {
                    $(this).addClass('all-slides-show');
                } else {
                    $(this).removeClass('all-slides-show');
                }
            });
            $('.s-slider-bg-image__text-slider').each(function () {
                let slidesCount = 0;
                let slidesActive = 0;
                $(this).find('.slider_block').each(function () {
                    slidesCount++;
                    if ($(this).hasClass('slick-active')) {
                        slidesActive++
                    }
                })
                if (slidesCount == slidesActive) {
                    $(this).addClass('all-slides-show');
                } else {
                    $(this).removeClass('all-slides-show');
                }
            });


        };
        detectAllSlidesIsShowing();


        function controlResize() {
            let resizeId;
            $(window).resize(function() {
                clearTimeout(resizeId);
                resizeId = setTimeout(doneResizing, 500);
            });
            function doneResizing(){
                setTimeout(() => {
                    $('.custom-slider__arrows-slider').slick('unslick');
                    controlSlickCustomArrowSlider();
                }, 500);
                detectAllSlidesIsShowing();
            }
        }
        controlResize();

        function aminate(items) {
            let _items = document.querySelectorAll(items);
            for(let item of [..._items]) {
                item.className += ' animated';
            }
        }

        aminate('.bg-arrows .bg-arrow');




        /**
         *  Slider Icons animated
         */

        let sliders = document.querySelectorAll('.animated_icons');
        let slides = [...sliders];

        if(typeof sliders !== 'undefined'){
            addIconAnimation(slides);
        }

        /**
         * Добавляем на скролл анимацию
         */
        document.addEventListener("scroll", function() {
            addIconAnimation(slides);
        });

        function addIconAnimation(slides) {
            for(let index in slides){
                showIcons(slides[index]);
            }
        }

        function showIcons(item) {
            if(item.classList.contains('visible')){

                setTimeout(function(){
                    item.classList.remove("hide-icons");
                }, 200);
            }
        }

        let setSliderNumbers = function(e, slick, currentSlide, nextSlide) {
            let totalCount = (currentSlide ? currentSlide : 0) + 1;
            let slidesLength = $('.s-slider__wrap .slick-slide:not(.slick-cloned)');

            let amountField = $('.s-slider__wrap .slider-count__amount');
            let currentField = $('.s-slider__wrap .slider-count__current');

            currentField.html(totalCount < 10 ? '0' + totalCount : totalCount);
            // let numField1 = $('.s-slider__wrap .s-slider__nums');
            // numField1.find('span').html(totalCount < 10 ? '0' + totalCount : totalCount);

            if(slick.slideCount){
                let currentCount = slick.slideCount < 10 ? '0' + slick.slideCount : slick.slideCount;
                amountField.html(currentCount);
            } else {
                let currentCount = slidesLength.length < 10 ? '0' + slidesLength.length : slidesLength.length;
                amountField.html(currentCount);
            }
        };

        /**
         * Создаем числа для анимации
         */
        let setSliderAnimationNumbers = function() {

            let numbersField = $('.s-slider__wrap .s-slider__nums');
            let slidesLength = $('.s-slider__wrap .slick-slide:not(.slick-cloned)');

            let numOfSlides = slidesLength.length;
            let nums = [...Array(numOfSlides).keys()].map(x => x+1);

            for(let num in nums) {
                let div = document.createElement('div');
                let span = document.createElement('span');

                div.className = 's-slider__num-item';

                if(nums[num] < 10) nums[num] = '0' + nums[num];

                span.innerHTML = nums[num];
                div.append(span);
                numbersField.append(div);
            }
        };

        $('.s-slider__items').on('init', setSliderAnimationNumbers);

        $('.s-slider__items').on('init afterChange', setSliderNumbers);

        $('.s-slider__items').slick({
            dots: false,
            arrows: true,
            speed: 300,
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: $('.s-slider__wrap .s-slider__nums'),
            prevArrow: $('.s-slider__wrap .s-slider-arrows__left'),
            nextArrow: $('.s-slider__wrap .s-slider-arrows__right'),
        });

        $('.s-slider__wrap .s-slider__nums').slick({
            speed: 300,
            variableWidth: true,
            slidesToShow: 1,
            asNavFor: $('.s-slider__items'),
            slidesToScroll: 1,
            prevArrow: $('.s-slider__wrap .s-slider-arrows__left'),
            nextArrow: $('.s-slider__wrap .s-slider-arrows__right'),
        });





        if ($(window).width() <= 1200) {
            $('.tabs__slider').slick({
                dots: false,
                infinite: false,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                variableWidth: true,
                arrows: false,
            });
        }
        $(window).resize(function() {
            if ($(window).width() <= 1200 && !$('.tabs__slider').hasClass('slick-slider')) {
                $('.tabs__slider').slick({
                    dots: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    variableWidth: true,
                    arrows: false
                })
            } else if ($(window).width() > 1200 && $('.tabs__slider').hasClass('slick-slider')) {
                $('.tabs__slider').slick('unslick');
            }
        });
    }
    // -- start services slider slick init --

    function slickSliderModalTrainersControl() {
        modalTrainersWasOpen = true;
        let commonArrows = function(event, slick, currentSlide){
            let totalCount = (currentSlide ? currentSlide : 0) + 1;
            let slidesLength = event.currentTarget.querySelectorAll('.slider_block').length;

            let amountField = $(this).closest('.modal-content').find('.slider-count__amount');

            if(slick.slideCount){
                let currentCount = slick.slideCount < 10 ? '0' + slick.slideCount : slick.slideCount;
                amountField.text(currentCount);
            } else {
                let currentCount = slidesLength.length < 10 ? '0' + slidesLength.length : slidesLength.length;
                amountField.text(currentCount);
            }
        };


        $('.custom-slider__arrows-slider_modal').on('init reInit afterChange', commonArrows);




        function controlSlickCustomArrowSlider() {
            if(document.querySelector('.custom-slider__arrows-slider') !== null){
                $('.custom-slider__arrows-slider_modal').each(function () {
                    let prevArr = $(this).closest('.modal-content').find('.s-slider-arrows__left');
                    let nextArr = $(this).closest('.modal-content').find('.s-slider-arrows__right');
                    $(this).slick({
                        infinite: true,
                        prevArrow: prevArr,
                        nextArrow: nextArr,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: false,
                        focusOnSelect: true,
                        autoplay: false,
                        fade: true,
                        speed: 0,
                        responsive: [
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 1
                                }
                            },
                        ]
                    });

                });

            }
        }
        controlSlickCustomArrowSlider();


        function detectAllSlidesIsShowing() {
            $('.custom-slider__arrows-slider_modal').each(function () {
                let slidesCount = 0;
                let slidesActive = 0;
                $(this).find('.slider_block').each(function () {
                    slidesCount++;
                    if ($(this).hasClass('slick-active')) {
                        slidesActive++
                    }
                })
                if (slidesCount == slidesActive) {
                    $(this).addClass('all-slides-show');
                } else {
                    $(this).removeClass('all-slides-show');
                }
            });
        };
        detectAllSlidesIsShowing();


        function controlResize() {
            let resizeId;
            $(window).resize(function() {
                clearTimeout(resizeId);
                resizeId = setTimeout(doneResizing, 500);
            });
            function doneResizing(){
                setTimeout(() => {
                    $('.custom-slider__arrows-slider_modal').slick('unslick');
                    controlSlickCustomArrowSlider();
                }, 500);
                detectAllSlidesIsShowing();
            }
        }
        controlResize();

        function aminate(items) {
            let _items = document.querySelectorAll(items);
            for(let item of [..._items]) {
                item.className += ' animated';
            }
        }

        aminate('.bg-arrows .bg-arrow');
    }


    //
    // /**
    //  *  Scroll to element
    //  */
    //
    // $('.scroll-to, a[href="#contactSection"]').on('click', function (e) {
    //     e.preventDefault();
    //     let linkHref = $(this).attr('href');
    //     if (linkHref !== '#' && $(linkHref + ':visible').length) {
    //         $('html, body').animate({
    //             scrollTop: $(linkHref).offset().top - 70
    //         }, 500);
    //     }
    // });

    /**
     * Задаем начальные цифры для слайдера
     */


    let scrollToLink = (el, parent) => {

        let topOffset = 100;
        $('html, body').animate({
            scrollTop: $(el).offset().top - topOffset
        }, 500);
    };

    $('.collapsible').collapsible({
        onOpenStart: function(el) {
            $(el).addClass('active');
        },
        onCloseStart: function(el) {
            $(el).removeClass('active');
        },
        onOpenEnd: function(el) {
            scrollToLink(el, this.el);
        }
    });
    $('select').formSelect();

    $('select').on('change', function () {
        $(this).closest('.select-wrapper').find('.dropdown-trigger').css('color','#111111');
    });

    $(document).on('change','.selected-field',function(){
        $(this).closest('.select-wrapper').find('.select-dropdown').css('color','#111111');
    });

    gdprCookieNotice({
        locale: 'en', //This is the default value
        timeout: 500, //Time until the cookie bar appears
        expiration: 60, //This is the default value, in days
        domain: '.altoros.com', //If you run the same cookie notice on all subdomains, define the main domain starting with a .
        implicit: false, //Accept cookies on page scroll automatically
        statement: '#', //Link to your cookie statement page
        performance: ['JSESSIONID', '_hjIncludedInSample', 'nQ_visitId', 'nQ_cookieId', 'locale', 'lang', '__atuvc', '__hstc', '__hssrc', '__hssc', 'hsfirstvisit', 'hubspotutk'], //Cookies in the performance category.
        analytics: ['ga', '_ga', '_gid', '_gat', 'collect', '_ga_user_id', '__utmz', '__utma', 'gwcc'], //Cookies in the analytics category.
        marketing: ['SSID', 'IDE', 'test_cookie', 'fr', 'tr', '1P_JAR', 'id', 'NID', 'APISID', 'SAPISID', 'guest_id', 'personalization_id', 'bcookie', 'bscookie', 'lidc', 'MUID', 'MUIDB', 'GPS'] //Cookies in the marketing category.
    });


    $('.s-slider-arrows__right').each( function() {
        $(this).one('click', function() {
            let slider = $(this).closest('section').find('.slick-slider');

            slider.slick('slickSetOption', {
                'autoplay': false
            }, false);
        });
    });

    $('.s-slider-arrows__left').each( function() {
        $(this).one('click', function() {
            let slider = $(this).closest('section').find('.slick-slider');

            slider.slick('slickSetOption', {
                'autoplay': false
            }, false);
        });
    });


    let addHashToLink  = ()=> {
        $('.s-board__name').each(function() {
            let prepared_name = $(this).html().toLowerCase().split(' ').join('_');

            let link = $(this).closest('.s-board__block').find('.s-board__preview');
            let href = link.attr('href');
            link.attr('href', href + '#' + prepared_name);
        });
    };

    addHashToLink();

    let scrollToBlock = ()=> {
        if(window.location.pathname !== '/management-team') return;

        let hash = window.location.hash;

        if(!hash) return;

        $('.s-people__name').each(function() {
            let prepared_name = $(this).html().toLowerCase().split(' ').join('_');
            $(this).closest('.s-people__block').attr('id', prepared_name);
        });

        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 500);
    };

    scrollToBlock();

    /**
     * Scrolling to bottom contact us section if there is a hash
     */
    let scrollToContactForm = ()=> {
        if(window.location.pathname !== '/contact-us') return;

        let hash = window.location.hash;

        if(!hash) return;

        let offset = 80;

        $('html, body').animate({
            scrollTop: $(hash).offset().top - offset
        }, 500);
    };

    scrollToContactForm();


    /**
     * Portfolio indicator
     */
    let setIndicator = (el) => {
        let tab_offsetLeft = el.position().left;
        let tab_width = el.width();
        let tabs_width = $('.s-portfolio .tabs').width();

        let tab_offsetRight = tabs_width - (tab_offsetLeft + tab_width);

        $('.s-portfolio .indicator').css('left', tab_offsetLeft);
        $('.s-portfolio .indicator').css('right', tab_offsetRight);
    };


    setTimeout(function(){
        var getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;



            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');
                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        };



        if (getUrlParameter('office') === 'usa') {

            $('title').text('Contacts | USA | Altoros');
            if(isMobile.any()){
                $('html, body').animate({
                    scrollTop: $("#usa").offset().top - 70
                }, 0);
            } else {
                $('html, body').animate({
                    scrollTop: $("#usa").offset().top - 90
                }, 0);
            }

        }
        else if(getUrlParameter('office') === 'norway')
        {

            $('title').text('Contacts | Norway | Altoros');
            if(isMobile.any()){
                $('html, body').animate({
                    scrollTop: $("#norway").offset().top - 70
                }, 0);
            } else {
                $('html, body').animate({
                    scrollTop: $("#norway").offset().top - 90
                }, 0);
            }
        }
        else if(getUrlParameter('office') === 'finland')
        {
            $('title').text('Contacts | Finland | Altoros');
            if(isMobile.any()){
                $('html, body').animate({
                    scrollTop: $("#finland").offset().top - 70
                }, 0);
            } else {
                $('html, body').animate({
                    scrollTop: $("#finland").offset().top - 90
                }, 0);
            }
        }
        else if(getUrlParameter('office') === 'argentina')
        {
            $('title').text('Contacts | Argentina | Altoros');
            if(isMobile.any()){
                $('html, body').animate({
                    scrollTop: $("#argentina").offset().top - 70
                }, 0);
            } else {
                $('html, body').animate({
                    scrollTop: $("#argentina").offset().top - 90
                }, 0);
            }
        }
        else if(getUrlParameter('office') === 'europe')
        {
            $('title').text('Contacts | Europe | Altoros');
            if(isMobile.any()){
                $('html, body').animate({
                    scrollTop: $("#europe").offset().top - 70
                }, 0);
            } else {
                $('html, body').animate({
                    scrollTop: $("#europe").offset().top - 90
                }, 0);
            }
        }


        detectTebPortfolio();

    }, 1500);

    function detectTebPortfolio() {
        // on resize
        $(window).resize(function() {
            if($(window).width() < 1200) return;

            $('.s-portfolio .tab').each(function () {
                if($(this).find('a').hasClass('active_tab')){
                    setIndicator($(this));
                }
            });
        });


        if($(window).width() < 1200) return;

        // init
        $('.s-portfolio .tab').each(function () {
            $(this).removeClass('active');

            if($(this).find('a').hasClass('active_tab')) {
                setIndicator($(this));
            }

            $(this).click(function () {
                let tab = $(this);

                setIndicator(tab);
            });
        });
    }


    function contactFormsConfig() {
        try {
            $('input[name=url_page]').val(location.origin + location.pathname);
            // $('input[name=lead_source_description]').val('Altoros | '+ location.pathname);
        }catch (e) {

        }
    }
    contactFormsConfig();

    $('input[type=hidden][name=lead_source_description]').after('<input type="hidden" name="p" value="">' +
        '<input type="hidden" name="client_id" value="">' +
        '<input type="hidden" name="gcl_id" value="">' +
        '<input type="hidden" name="campaign_name" value="">' +
        '<input type="hidden" name="campaign_source" value="">' +
        '<input type="hidden" name="campaign_channel" value="">' +
        '<input type="hidden" name="ad_group" value="">' +
        '<input type="hidden" name="search_queries" value="">' +
        '<input type="hidden" name="session_count" value="">' +
        '<input type="hidden" name="keyword_match_type" value="keyword">' +
        '<input type="hidden" name="utm_term" value="">' +
        '<input type="hidden" name="match_type" value="">' +
        '<input type="hidden" name="device" value="">' +
        '<input type="hidden" name="project_type" value="">');


    $('input[name="url_page"]').val($('input[name="url_page"]').val() + window.location.search);

    var date = new Date(new Date().getTime() + 60 * 48 * 3600 * 1000);

    function getCookie(name) {
        var matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    /**
     * BEGIN: UTM метки
     * все метки вида utm_.* в query части запроса должны обрабатыватся
     */

    var queryDict = {};
    location.search.substr(1).split("&").forEach(function (item) {
        queryDict[item.split("=")[0]] = item.split("=")[1];
    });

    var utm_list = [
        {key: 'p', sugar: 'p'},
        {key: 'utm_source', sugar: 'campaign_source'},
        {key: 'utm_medium', sugar: 'campaign_channel'},
        {key: 'utm_campaign', sugar: 'campaign_name'},
        {key: 'utm_content', sugar: 'ad_group'},
        {key: 'utm_term', sugar: 'utm_term'},
        {key: 'utm_term', sugar: 'search_queries'},
        {key: 'matchtype', sugar: 'match_type'},
        {key: 'device', sugar: 'device'},
    ];

    utm_list.forEach(function (utm_item) {
        utm_item.val = '';
        if (getCookie(utm_item.key) !== "undefined") {
            utm_item.val = getCookie(utm_item.key);
        }
        if (typeof queryDict[utm_item.key] !== "undefined") {
            document.cookie = utm_item.key + "=" + queryDict[utm_item.key] + ";path=/;expires=" + date.toUTCString();
            utm_item.val = decodeURIComponent(queryDict[utm_item.key]);
        }
        $('input[name="' + utm_item.sugar + '"]').val(utm_item.val);

        if (utm_item.key === 'utm_source' && utm_item.val === 'influ2') {
            $('input[name="p"]').val(utm_item.val);
        }
    });

    if (window.location.href.indexOf("utm_campaign") > -1) {
        document.cookie = 'url_page' + "=" + window.location.search + ";path=/;expires=" + date.toUTCString();
    }

    /**
     * END: UTM метки
     */

    // $(document).change(function () {
    //     if (document.cookie.match(/_ga=(.+?);/)) {
    //         var gaUserId = document.cookie.match(/_ga=(.+?);/)[1].split('.').slice(-2).join(".");
    //         $('input[name="client_id"]').val(gaUserId);
    //     }
    // });
    $(document).change(function () {
        if(ga.length){
            $('input[name="client_id"]').val(ga.getAll()[0].get('clientId'));
            $('input[name="gcl_id"]').val(ga.getAll()[0].get('_gclid'));
        }
    });

    $('#bottomReseller').on('click', function(){
        let projectTypeReseller = 'm_0.000';
        $('input[name="project_type"]').val(projectTypeReseller);
    });

    $('#bottomTrial').on('click', function(){
        addMessageTrial('#bottomForm');
    });

    $('#topTrial').on('click', function(){
        addMessageTrial('#bottomForm');
    });

    $('#webinarTrial').on('click', function(){
        addMessageTrial('#bottomForm');
    });

    $('#bannerTrial').on('click', function(){
        addMessageTrial('#bottomForm');
    });

    $('.trial-trigger').on('click', function(){
        addMessageTrial('#bottomForm');
    });

    function addMessageTrial(selector) {
        let messageTrial = 'Start free trial';
        let form = document.querySelector(selector);
        $(form).find('[name=message]').val(messageTrial);
    }

    /**
     * Popup for photos in slider
     */

    $('.modal-trigger-photo').each(function() {
        $(this).click(function () {
            let image = $(this).attr('data-image');
            $('.modal_photo').find('img').attr('src', image);
        });
    });

    $('.modal_photo').modal();

    // opens more text after click
    $('.s-implementation .toggle-block').on('click', function () {
        $(this).closest('.s-implementation__blocks-wrap').find('.s-implementation__block').each(function () {
            $(this).find('.s-implementation__block-inner').toggleClass('open');
        })
    })
    // opens more text after click
    $('.s-clients .toggle-block').on('click', function () {
        $(this).closest('.s-clients__blocks').find('.s-clients__block').each(function () {
            $(this).find('.s-clients__block-inner').toggleClass('open');
        })
        if ($(this).text() === 'Hide') {
            $(this).text("More")
        } else if ($(this).text() === 'More') {
            $(this).text("Hide")
        }
    })



    if($('.s-training-program').find('.s-videos__video-wrap').length > 0) {
        let section = $('.s-training-program .collapsible').find('li');

        section.each(function() {
            let time = [];
            let video = $(this).find('.s-videos__video-wrap');

            video.each(function () {
                time.push($(this).attr('data-videolength'));
            });

            time = time.reduce(function(sum, num) {
                return sum + Number(num);
            }, 0);

            let timePrepared = time + 'm';

            $(this).find('.info-time').html(timePrepared);
        });
    }

    //Open modal privacy policy inside another modal window
    $('.modalPrivacyLink').on('click', function (e) {
        e.preventDefault()
        let currentLink = $(this);
        $('.modal').modal('close');

        setTimeout(function () {
            $('#privacyPolicyModal').modal('open');

        }, 300)
        $('#privacyPolicyModal').modal({
            onCloseEnd: function () {
                currentLink.closest('.modal').modal('open');
                $('#privacyPolicyModal').modal()
            }
        });
    })


    function addLinkVideo(link, url) {
        $('a[href="#formSeeDemoWithLinkedin"]').attr({'href': link, 'target':'_blank', 'rel':"nofollow noopener"});

        window.open(link, '_blank');
        window.history.pushState({},'', url);

        var flag = false;
        dataLayer.forEach(function (el) {
            if (el["event"] == "lead_source_type_c") {
                flag = true;
                el["name"] = "See demo viveo";
            }
        });
        if (!flag) {
            dataLayer.push(
                {
                    "event": "lead_source_type_c",
                    "name": "See demo viveo"
                }
            )
        }
    }

    if (window.location.search == '?q=seeLiveDemo')  {

        // $('#popup-frame').modal('open');
        // $('#popup-frame iframe').removeClass('loaded');

        let url = window.location.href;
        let newUrl = window.location.pathname;

        if(url.includes('car-damage-recognition')){
            let linkVideo = 'https://www.altoros.com/assets/video/video-2.mp4';
            addLinkVideo(linkVideo, newUrl);
        }else if(url.includes('pdf-mining')){
            let linkVideo = 'https://www.altoros.com/assets/video/video-1.mp4';
            addLinkVideo(linkVideo, newUrl);
        }

    }

    function addLinkPdf(link, url) {

        $('a[href="#formDownloadWithLinkedinShort"]').find('span').text('Open a brochure');
        $('a[href="#formDownloadWithLinkedinShort"]').attr({'href': link, 'target':'_blank', 'rel':"nofollow noopener"});

        window.open(link, '_blank');
        window.history.pushState({},'', url);


        var flag = false;
        dataLayer.forEach(function (el) {
            if (el["event"] == "lead_source_type_c") {
                flag = true;
                el["name"] = "Download PDF";
            }
        });
        if (!flag) {
            dataLayer.push(
                {
                    "event": "lead_source_type_c",
                    "name": "Download PDF"
                }
            )
        }
    }

    if (window.location.search == '?q=seeLiveBrochure')  {

        let url = window.location.href;
        let newUrl = window.location.pathname;

        if(url.includes('uvc-disinfection-robot')){
            let linkBrochure = 'https://www.altoros.com/assets/pdf/UV_Robots.pdf';
            addLinkPdf(linkBrochure, newUrl);
        }else if(url.includes('uvc-disinfection-robot-50')){
            let linkBrochure = 'https://www.altoros.com/assets/pdf/UV_Robots_C.pdf';
            addLinkPdf(linkBrochure, newUrl);
        }else if(url.includes('manual-cleaning-app')){
            let linkBrochure = 'https://www.altoros.com/assets/pdf/Manual_Cleaning_Brochure.pdf';
            addLinkPdf(linkBrochure, newUrl);
        }else if(url.includes('mass-fever-screener-with-ai')){
            let linkBrochure = 'https://www.altoros.com/assets/pdf/AI-based-Fever-Screener.pdf';
            addLinkPdf(linkBrochure, newUrl);
        }else if(url.includes('alarta-platform')){
            let linkBrochure = 'https://www.altoros.com/assets/pdf/Alarta-brochure-3.0.pdf';
            addLinkPdf(linkBrochure, newUrl);
        }else if(url.includes('uvc-disinfection-station')){
            let linkBrochure = 'https://www.altoros.com/assets/pdf/UVC-Disinfection-Station.pdf';
            addLinkPdf(linkBrochure, newUrl);
        }

    }

    function addOgUrl() {
        let url = window.location.href;
        $("meta[property='og:url']").attr("content", url);
    }
    addOgUrl();

    // $('body').on('click','.gdpr-cookie-notice-modal-footer-item-statement', function () {
    //     var win = window.open('/privacy-policy', '_blank');
    //     win.focus();
    // });

    function numberWithSpaces(x) {
        if ((x != undefined) || (x != null)) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        } else {
            return
        }
    }

    //to take price from select element to form
    controlPricesSection();
    function controlPricesSection() {
        var selectedPrice = $('.s-prices-select').val();
        $('.s-prices-result-item').text(numberWithSpaces(selectedPrice) + '$');
        $('.s-prices-select, .s-prices-select-popup').change(function(){
            let priceVal = $(this).val();
            if (Number(priceVal)) {
                $('.s-prices-result-item').text(numberWithSpaces(priceVal) + '$');
            } else {
                $('.s-prices-result-item').text(priceVal);
            }
        });
        $('.s-prices-select-popup').change(function(){
            $('.s-prices-select').val($('.s-prices-select-popup').val()).formSelect();
        });
        $('#robotPrice').modal({
            onOpenStart: function() {
                $('.s-prices-select-popup').val($('.s-prices-select').val()).formSelect();
            },
            onCloseEnd: function () {
                $('.s-prices-select').val($('.s-prices-select-popup').val()).formSelect();
            }
        });
    }

    searchMenuController();
    function searchMenuController() {
        $('.search_btn-menu').on('click', function(e) {
            e.preventDefault();

            $('.menu-search-item').addClass('active');
            $('.search_btn').addClass('hided').removeClass('showed');
            $('.hide-on-med-and-down').addClass('hided').removeClass('showed');
        })

        $(document).click(function(e) {
            if (!$(e.target).closest('.menu-search-item').length) {
                $('.menu-search-item').removeClass('active');
                $('.search_btn').addClass('showed').removeClass('hided');
                $('.hide-on-med-and-down').removeClass('hided');
            }
        });
    }

    rateMark();
    function rateMark() {
        let labelIndex = $('.s-contact__rate-stars label');
        let rateImageSrc = $('.s-contact__rate-image img');
        $('.s-contact__rate-label').on('click', function(e) {
            $(this).addClass('active').prevAll($('label')).addClass('active');
            $(this).nextAll($('label')).removeClass('active');
            if (labelIndex.index(this) == 0) {
                rateImageSrc.attr("src", "/assets/img/rate_1.svg")
            } else if (labelIndex.index(this) == 1) {
                rateImageSrc.attr("src", "/assets/img/rate_2.svg")
            } else if (labelIndex.index(this) == 2) {
                rateImageSrc.attr("src", "/assets/img/rate_3.svg")
            } else if (labelIndex.index(this) == 3) {
                rateImageSrc.attr("src", "/assets/img/rate_4.svg")
            } else if (labelIndex.index(this) == 4) {
                rateImageSrc.attr("src", "/assets/img/rate_5.svg")
            }
        });
    }

    controlPricesSafetySection();
    function controlPricesSafetySection() {
        var costPrice = 0;
        var installPrice = 0;
        var procLine = 0;
        var installPriceFlag = true;

        $('.s-prices-safety .open-form').on('click', function (e) {
            e.preventDefault();
            $('.s-prices-safety .s-prices-content').addClass('active');
            if ($(this).closest('.btn__wrap').prev('.price').hasClass('price-estimated')) {
                $('.s-order-select').val('Estimated cost').formSelect();
            } else  if ($(this).closest('.btn__wrap').prev('.price').hasClass('price-installments')) {
                $('.s-order-select').val('By installments').formSelect();
            } else  if ($(this).closest('.btn__wrap').prev('.price').hasClass('price-lease')) {
                $('.s-order-select').val('On lease').formSelect();
            }

            $('.safety-form-price').text($(this).closest('.btn__wrap').prev('.price').text());

        });

        $('.s-order-select').on('change', function () {
            if ($(this).val() == 'Estimated cost') {
                $('.safety-form-price').text($('.price-estimated').text());
            } else if ($(this).val() == 'By installments') {
                $('.safety-form-price').text($('.price-installments').text());
            } else if ($(this).val() == 'On lease') {
                $('.safety-form-price').text($('.price-lease').text());
            }
        });

        $('.s-prices-safety .s-prices-select').on('change', function () {
            costPrice = 0;
            installPrice = 0;
            procLine = 0;
            installPriceFlag = true;

            var tempVal = $('.s-prices-safety .s-prices-select--temperature').val();
            var checkappVal = $('.s-prices-safety .s-prices-select--checkapp').val();
            var mobileareaVal = $('.s-prices-safety .s-prices-select--mobilearea').val();
            var stationaryareaVal = $('.s-prices-safety .s-prices-select--stationaryarea').val();
            var cleaningappVal = $('.s-prices-safety .s-prices-select--cleaningapp').val();
            var contacttracingVal = $('.s-prices-safety .s-prices-select--contacttracing').val();

            if (tempVal != '0') {
                procLine += 30;
            }
            if (checkappVal != '0') {
                procLine += 10;
            }
            if ((mobileareaVal != '0') || (stationaryareaVal != '0')) {
                procLine += 35;
            }
            if (cleaningappVal != '0') {
                procLine += 13;
            }
            if (contacttracingVal != '0') {
                procLine += 10;
            }


            if (tempVal == '1') {
                costPrice += 13800;
                installPrice += 2460;
            } else if (tempVal == '2') {
                costPrice += 27600;
                installPrice += 4920;
            } else if (tempVal == '3') {
                costPrice += 41400;
                installPrice += 7380;
            } else if (tempVal == '4') {
                costPrice += 55200;
                installPrice += 9840;
            } else if (tempVal == '5') {
                costPrice += 69000;
                installPrice += 12300;
            } else if (tempVal == 'more') {
                $('.s-prices-safety .price-estimated').text('By the request');
                $('.s-prices-safety .price-installments').text('By the request');
                return;
            }

            if (checkappVal == '1') {
                costPrice += 120;
                installPriceFlag = false;
            } else if (checkappVal == '2') {
                costPrice += 240;
                installPriceFlag = false;
            } else if (checkappVal == '3') {
                costPrice += 360;
                installPriceFlag = false;
            } else if (checkappVal == '4') {
                costPrice += 480;
                installPriceFlag = false;
            } else if (checkappVal == '5') {
                costPrice += 600;
                installPriceFlag = false;
            } else if (checkappVal == 'more') {
                $('.s-prices-safety .price-estimated').text('By the request');
                $('.s-prices-safety .price-installments').text('By the request');
                return;
            }

            if (mobileareaVal == '2150sq.ft') {
                costPrice += 14800;
                installPrice += 2640;
            } else if (mobileareaVal == '4700sq.ft') {
                costPrice += 29600;
                installPrice += 5280;
            } else if (mobileareaVal == '6300sq.ft') {
                costPrice += 44400;
                installPrice += 7920;
            } else if (mobileareaVal == '8500sq.ft') {
                costPrice += 59200;
                installPrice += 10560;
            } else if (mobileareaVal == 'more') {
                $('.s-prices-safety .price-estimated').text('By the request');
                $('.s-prices-safety .price-installments').text('By the request');
                return;
            }

            if (stationaryareaVal == '50sq.ft') {
                costPrice += 1000;
                installPrice += 190;
            } else if (stationaryareaVal == '100sq.ft') {
                costPrice += 2000;
                installPrice += 380;
            } else if (stationaryareaVal == '150sq.ft') {
                costPrice += 3000;
                installPrice += 570;
            } else if (stationaryareaVal == '200sq.ft') {
                costPrice += 4000;
                installPrice += 760;
            } else if (stationaryareaVal == 'more') {
                $('.s-prices-safety .price-estimated').text('By the request');
                $('.s-prices-safety .price-installments').text('By the request');
                return;
            }

            if (cleaningappVal == '1') {
                costPrice += 120;
                installPriceFlag = false;
            } else if (cleaningappVal == '2') {
                costPrice += 240;
                installPriceFlag = false;
            } else if (cleaningappVal == '3') {
                costPrice += 360;
                installPriceFlag = false;
            } else if (cleaningappVal == '4') {
                costPrice += 480;
                installPriceFlag = false;
            } else if (cleaningappVal == '5') {
                costPrice += 600;
                installPriceFlag = false;
            } else if (cleaningappVal == 'more') {
                $('.s-prices-safety .price-estimated').text('By the request');
                $('.s-prices-safety .price-installments').text('By the request');
                return;
            }

            if (contacttracingVal == '1') {
                costPrice += 120;
                installPriceFlag = false;
            } else if (contacttracingVal == '2') {
                costPrice += 240;
                installPriceFlag = false;
            } else if (contacttracingVal == '3') {
                costPrice += 360;
                installPriceFlag = false;
            } else if (contacttracingVal == '4') {
                costPrice += 480;
                installPriceFlag = false;
            } else if (contacttracingVal == '5') {
                costPrice += 600;
                installPriceFlag = false;
            } else if (contacttracingVal == 'more') {
                $('.s-prices-safety .price-estimated').text('By the request');
                $('.s-prices-safety .price-installments').text('By the request');
                return;
            }

            $('.s-prices-safety .price-estimated').text(numberWithSpaces(costPrice) + '$');
            if (installPriceFlag) {
                $('.s-prices-safety .price-installments').text(numberWithSpaces(installPrice) + '$');
            } else {
                $('.s-prices-safety .price-installments').text('By the request');
            }

            if ($('.s-prices-content').hasClass('active')) {
                if ($('.s-order-select').val() == 'Estimated cost') {
                    $('.safety-form-price').text(numberWithSpaces(costPrice) + '$')
                } else if ($('.s-order-select').val() == 'By installments') {
                    if (installPriceFlag) {
                        $('.safety-form-price').text(numberWithSpaces(installPrice) + '$');
                    } else {
                        $('.safety-form-price').text('By the request');
                    }
                } else if ($('.s-order-select').val() == 'On lease') {
                    $('.safety-form-price').text('By the request');
                }
            }


            $('.s-prices-line-wrap .current').css('left', procLine + '%');
            if (procLine != 0) {
                $('.s-prices-line-wrap .current').css('transform', 'translateX(-100%)');
            }
            $('.s-prices-line-wrap .current').text(procLine + '%');
            $('.s-prices-line-wrap .line-active').css('width', procLine + '%');




        })
    }



    pilotFormControl()
    function pilotFormControl() {
        $('.pilot-btn').on('click', function (e) {
            e.preventDefault();
            $('#pilotBannerModal').modal('open');
        });
        $('.open-pilot-form').on('click', function (e) {
            e.preventDefault();
            $('#pilotBannerModal').modal('close');

            setTimeout(function () {
                $('#pilotFormModal').modal('open');
            }, 300);
        });
        $('.pilot-form__steps-btns .btn__next-step-control--checkbox').on('click', function (e) {
            e.preventDefault();
            var flag= false;
            $(this).closest('.form-step').removeClass('invalid');

            $(this).closest('.pilot-form__step').find('.pilot-form__check-block').each(function () {
                if ($(this).hasClass('active')) {
                    flag = true;
                }
            });
            if (flag) {
                $(this).closest('.form-step').removeClass('active').next('.form-step').addClass('active')
            } else {
                $(this).closest('.form-step').addClass('invalid');
            }

        })
        $('.pilot-form__check-block').on('click', function () {
            $(this).toggleClass('active');
        })
        $('.pilot-thanks-close').on('click', function (e) {
            e.preventDefault();
            $('#pilotThanksModal').modal('close');
        })
    }

    function calcAlartaTime() {
        $('#calculatorTimeStation .timecalc-select').change(function(){
            let furniture = $('#calculatorTimeStation .select-furniture'),
                geometry = $('#calculatorTimeStation .select-geometry'),
                resultText = $('#calculatorTimeStation .timecalc-result-value'),
                resultTextOrdinary = $('#calculatorTimeStation .timecalc-result-calc'),
                resultTextUnordinary = $('#calculatorTimeStation .timecalc-result-notcalc')


            if ((geometry.val() == "Irregular" && furniture.val() == "No") || furniture.val() == "Yes") {
                console.log("NO CALC")
                resultTextOrdinary.addClass('hidden');
                resultTextUnordinary.removeClass('hidden');
            } else {
                console.log("CALC")

                resultTextOrdinary.removeClass('hidden');
                resultTextUnordinary.addClass('hidden');

                let calcFootage = $('#calculatorTimeStation .select-footage').val(),
                    calcHeight = $('#calculatorTimeStation .select-height').val(),
                    result = parseInt(calcFootage * calcHeight / 302 * 60)


                resultText.text(result + ' ' + 'min');
            }


        })

        $('#calculatorTimeLamp .timecalc-select').change(function(){
            let furniture = $('#calculatorTimeLamp .select-furniture'),
                geometry = $('#calculatorTimeLamp .select-geometry'),
                resultText = $('#calculatorTimeLamp .timecalc-result-value'),
                resultTextOrdinary = $('#calculatorTimeLamp .timecalc-result-calc'),
                resultTextUnordinary = $('#calculatorTimeLamp .timecalc-result-notcalc')


            if ((geometry.val() == "Irregular" && furniture.val() == "No") || furniture.val() == "Yes") {
                console.log("NO CALC")
                resultTextOrdinary.addClass('hidden');
                resultTextUnordinary.removeClass('hidden');
            } else {
                console.log("CALC")

                resultTextOrdinary.removeClass('hidden');
                resultTextUnordinary.addClass('hidden');

                let calcFootage = $('#calculatorTimeLamp .select-footage').val(),
                    calcHeight = $('#calculatorTimeLamp .select-height').val(),
                    result = parseInt(calcFootage * calcHeight / 500 * 60)


                resultText.text(result + ' ' + 'min');
            }


        })

        $('.timecalc-result-subtext .animated-scroll').click(function() {
            $(this).closest('.modal').modal('close');
        })
    }
    calcAlartaTime()


    // calc height in sPackage section
    function calcPriceHeight(elementChange) {
        let heightArray = [];

        // check each elem height including padding
        elementChange.each( function () {
            let elemHeight = $(this).innerHeight()
            heightArray.push(elemHeight);
        });
        //put all heights into array
        let array = $.map(heightArray, function(value){
            return [value];
        });

        //for emply
        if (elementChange.is($(".s-package-slider-price-top .s-package-slider-price-block"))) {
            $('.s-package-slider-row-titles').css({'paddingTop' : Math.max.apply(Math, array)})
        }

        // apply to all same elements same height
        elementChange.css({'minHeight' : Math.max.apply(Math, array)})
    }

    calcPriceHeight($(".s-package-slider-price-top .s-package-slider-price-block"))
    calcPriceHeight($(".s-package-slider-price-top .s-package-slider-price-title"))
    calcPriceHeight($(".s-package-slider-col-item-height_1"))
    calcPriceHeight($(".s-package-slider-col-item-height_2"))
    calcPriceHeight($(".s-package-slider-col-item-height_3"))
    calcPriceHeight($(".s-package-slider-col-item-height_4"))
    calcPriceHeight($(".s-package-slider-col-item-height_5"))
    calcPriceHeight($(".s-package-slider-col-item-height_6"))
    // calc height in sPackage section when resize
    $(window).resize(function() {
        calcPriceHeight($(".s-package-slider-price-top .s-package-slider-price-block"))
        calcPriceHeight($(".s-package-slider-price-top .s-package-slider-price-title"))
        calcPriceHeight($(".s-package-slider-col-item-height_1"))
        calcPriceHeight($(".s-package-slider-col-item-height_2"))
        calcPriceHeight($(".s-package-slider-col-item-height_3"))
        calcPriceHeight($(".s-package-slider-col-item-height_4"))
        calcPriceHeight($(".s-package-slider-col-item-height_5"))
        calcPriceHeight($(".s-package-slider-col-item-height_6"))
    });


    controlROISection();
    function controlROISection() {
        var busines = '';
        var priceType = 'For Users';
        var paymentScript = '';
        var constPrice = 22000;

        $('.s-roi-calculator-1 .btn__prev-step').on('click', function (e) {
            e.preventDefault();
            $('.s-roi-calculator__step-block.active').removeClass('active').prev('.s-roi-calculator__step-block').addClass('active');
            $('html, body').animate({
                scrollTop: $('#roiCalculatorSection').offset().top - 90
            }, 1000);
        });
        $('.s-roi-calculator-1 .btn__next-step').on('click', function (e) {
            e.preventDefault();
            if ($('.s-roi-calculator__step-block.active').hasClass('valid')) {
                $('.s-roi-calculator__step-block.active').removeClass('active').next('.s-roi-calculator__step-block').addClass('active');
                $('html, body').animate({
                    scrollTop: $('#roiCalculatorSection').offset().top - 90
                }, 1000);
            } else {
                $('.s-roi-calculator__step-block.active').addClass('invalid')
            }

        });

        $('.s-roi-calculator__item--radio').on('click', function () {
            $('.s-roi-calculator__item--radio').each(function () {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
            busines = $(this).attr('data-id');

            // if (busines == 'Hotels') {
            //     constPrice = 118794;
            // } else if (busines == 'Restaurant') {
            //     constPrice = 30545;
            // } else if (busines == 'Other') {
            //     constPrice = 41400;
            // }

            $(this).closest('.s-roi-calculator__step-block').removeClass('invalid').addClass('valid');
        });

        $('.s-roi-calculator__item--price-type select').on('change', function () {
            $('.s-roi-calculator__items-wrap--payment-script').each(function () {
                $(this).removeClass('active');
            });
            if ($(this).val() == 'For Users') {
                $('.s-roi-calculator__items-wrap--payment-script').each(function () {
                    if ($(this).attr('data-id') == 'users') {
                        $(this).addClass('active')
                    }
                });
            } else if ($(this).val() == 'For Resellers') {
                $('.s-roi-calculator__items-wrap--payment-script').each(function () {
                    if ($(this).attr('data-id') == 'resellers') {
                        $(this).addClass('active')
                    }
                });
            }

            priceType = $(this).val();
        });

        $('.s-roi-calculator__item--payment-script').on('click', function () {
            $('.s-roi-calculator__item--payment-script').each(function () {
                $(this).removeClass('active');
                $(this).find('.input-checkbox').prop("checked", false);
            });
            $(this).addClass('active');
            $(this).find('.input-checkbox').prop("checked", true);

            paymentScript = $(this).find('span').text();

            $(this).closest('.s-roi-calculator__step-block').removeClass('invalid').addClass('valid');
        });

        $('.s-roi-calculator__item--module').on('click', function () {
            if (!$(this).hasClass('active')) {
                $(this).addClass('active');
                $(this).find('.input-checkbox').prop("checked", true);
            } else {
                $(this).removeClass('active');
                $(this).find('.input-checkbox').prop("checked", false);
            }

            var checkModule = false;
            $('.s-roi-calculator__item--module').each(function () {
                if ($(this).hasClass('active')) {
                    checkModule = true;
                }
            })

            if (checkModule) {
                $(this).closest('.s-roi-calculator__step-block').removeClass('invalid').addClass('valid');
            } else {
                $(this).closest('.s-roi-calculator__step-block').removeClass('valid').addClass('invalid');
            }

        });

        $('.s-roi-calculator .btn__next-step--calc-res').on('click', function () {
            $('.s-roi-calculator__results--left').html('');
            $('.s-roi-calculator__results--left').append("<div class='s-roi-calculator__result-block'><p>" + busines + "</p><div>");
            $('.s-roi-calculator__results--left').append("<div class='s-roi-calculator__result-block'><p>" + priceType + "</p><div>");
            $('.s-roi-calculator__results--left').append("<div class='s-roi-calculator__result-block'><p>" + paymentScript + "</p><div>");
            $('.s-roi-calculator__item--module').each(function () {
                if ($(this).hasClass('active')) {
                    // $('.s-roi-calculator__results--left').append("<div class='s-roi-calculator__result-block s-roi-calculator__result-block--module' data-val='" + $(this).find('.input-checkbox').attr('data-val') + "'><p>" + $(this).find('span').text() + "</p><img class='s-roi-calculator__result-block-cross image_svg' src='/assets/img/cross-icon.svg'><div>");
                    $('.s-roi-calculator__results--left').append("<div class='s-roi-calculator__result-block s-roi-calculator__result-block--module' data-val='" + $(this).find('.input-checkbox').attr('data-val') + "'><p>" + $(this).find('span').text() + "</p><div>");
                }
            });
            addInlineSvg();

            var blocksSum = 0;

            $('.s-roi-calculator__result-block--module').each(function () {
                blocksSum += +$(this).attr('data-val');
            });

            if (blocksSum != 0) {
                var result = (constPrice / blocksSum) * 100;

                $('.formula__result').text('= ' + result.toFixed(2) + '%');
            } else {
                $('.formula__result').text('= 0%');
            }


        });

        $('.s-roi-calculator').on('click', '.s-roi-calculator__result-block-cross', function () {
            $(this).closest('.s-roi-calculator__result-block--module').remove();
            var blocksSum = 0;

            $('.s-roi-calculator__result-block--module').each(function () {
                blocksSum += +$(this).attr('data-val');
            });

            if (blocksSum != 0) {
                var result = (constPrice / blocksSum) * 100;

                $('.formula__result').text('= ' + result.toFixed(2) + '%');
            } else {
                $('.formula__result').text('= 0%');
            }


        });

        $('.s-roi-calculator__finish-btn').on('click', function (e) {
            e.preventDefault();
            var activeModules = [];

            $('.s-roi-calculator__result-block--module').each(function () {
                activeModules.push($(this).find('p').text());
            });

            $('#popupFreeTrialFormEmailRoi #textarea_trial_results').val('Busines: ' + busines + '; Price type: ' + priceType + '; Payment script: ' + paymentScript + '; Modules: ' + activeModules.join(', ') + '')
        })

    }

    $('.show-trial-mail-form').on('click', function () {
        $(this).removeClass('active');
        $('.form__trial--mail').addClass('active')
    })
    $('.trial-btn').on('click', function (e) {
        e.preventDefault();
        $('#formFreeTrialModal').modal('open');
    })
    $('.discount-btn').on('click', function (e) {
        e.preventDefault();
        $('#popupFormDiscountModal').modal('open');
    })

    controlROISection2();
    function controlROISection2() {
        var busines = '';
        var clientsRange = 1;
        var averageRange = 1;
        var obligation = 1000;
        var leadCosts = 750;
        var insuranceCosts = 75;
        var resultCost = 0;

        $('.s-roi-calculator-2 .btn__prev-step').on('click', function (e) {
            e.preventDefault();
            $('.s-roi-calculator__step-block.active').removeClass('active').prev('.s-roi-calculator__step-block').addClass('active');
            $('html, body').animate({
                scrollTop: $('#roiCalculatorSection2').offset().top - 90
            }, 1000);
        });
        $('.s-roi-calculator-2 .btn__next-step').on('click', function (e) {
            e.preventDefault();
            if ($('.s-roi-calculator__step-block.active').hasClass('valid')) {
                $('.s-roi-calculator__step-block.active').removeClass('active').next('.s-roi-calculator__step-block').addClass('active');
                $('html, body').animate({
                    scrollTop: $('#roiCalculatorSection2').offset().top - 90
                }, 1000);
            } else {
                $('.s-roi-calculator__step-block.active').addClass('invalid')
            }

        });

        $('.s-roi-calculator__item--radio').on('click', function () {
            $('.s-roi-calculator__item--radio').each(function () {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
            busines = $(this).attr('data-id');

            $(this).closest('.s-roi-calculator__step-block').removeClass('invalid').addClass('valid');
        });



        $('.s-roi-calculator-2__calc-btn').on('click', function (e) {
            e.preventDefault()
            clientsRange = +$('#roiClientsRange .noUi-tooltip').text();
            averageRange = +$('#roiAverageRange .noUi-tooltip').text();
            console.log(clientsRange)

            resultCost = clientsRange * averageRange + obligation + leadCosts + insuranceCosts;

            $('.s-roi-calculator-2__res-text').text(numberWithSpaces(resultCost));
            $('#popupFreeTrialFormEmailRoi #textarea_trial_results').val('Busines: ' + busines + '; Clients per month before COVID: ' + clientsRange + '; Average revenue per client (ARPC), $: ' + averageRange + '; Result Cost: ' + resultCost + ';')
        });





        if ($('#roiClientsRange').length) {
            var slider = document.getElementById('roiClientsRange');
            noUiSlider.create(slider, {
                start: 0,
                connect: 'lower',
                tooltips: [true],
                step: 1,
                orientation: 'horizontal', // 'horizontal' or 'vertical'
                range: {
                    'min': 1,
                    'max': 100000
                },
                format: wNumb({
                    decimals: 0
                }),
                pips: {
                    mode: 'values',
                    values: [1, 100000],
                    format: wNumb({
                        decimals: 0
                    })
                }
            });
        }

        if ($('#roiAverageRange').length) {
            var slider = document.getElementById('roiAverageRange');
            noUiSlider.create(slider, {
                start: 0,
                connect: 'lower',
                tooltips: [true],
                step: 1,
                orientation: 'horizontal', // 'horizontal' or 'vertical'
                range: {
                    'min': 1,
                    'max': 10000
                },
                format: wNumb({
                    decimals: 0
                }),
                pips: {
                    mode: 'values',
                    values: [1, 10000],
                    format: wNumb({
                        decimals: 0
                    })
                }
            });
        }

    }



    // add autoanimation but remove it when hovered
    let cardIntervalDelay = 5000
    let cardInterval = setInterval(benefitsAnimation, cardIntervalDelay)

    function benefitsAnimation() {

        if ($('.s-benefits-block.active').attr('data-id') == '5') {
            $('.s-benefits-block.active').removeClass('active')
            $('.s-benefits-block[data-id=0]').addClass('active')
        } else {
            $('.s-benefits-block.active').removeClass('active').next().addClass('active')
        }
    }
    $('.s-benefits-content').mouseenter(function () {
        clearInterval(cardInterval)
        $('.s-benefits-block').each(function () {
            $(this).removeClass('active')
        })
    })

    $('.s-benefits-content').mouseleave(function () {
        $('.s-benefits-block[data-id=0]').addClass('active')
        cardInterval = setInterval(benefitsAnimation, cardIntervalDelay)
    })


    // START: toggle tabs on hover in dropdown menu
    const dropdownBlocks = document.querySelectorAll('.dropdown');
    const tabLinks = document.querySelectorAll('.dropdown__tabs-link');
    const tabsContent = document.querySelectorAll('.dropdown__content');

    if (tabLinks) {
        for (let tabItem of tabLinks) {
            tabItem.addEventListener('mouseover', (event) => {

                // удаляем все классы active из блоков контента
                for (let content of tabsContent) {
                    content.style.display = 'none';
                }

                // делаем то же самое, но теперь для табов
                for (let tab of tabLinks) {
                    tab.classList.remove('activeTab');
                }

                // если id совпадают, то добавляем необходимый стиль
                let currentTarget = event.currentTarget;
                for (let content of tabsContent) {
                    if ((currentTarget.dataset.href).slice(1) === content.id) {
                        content.style.display = 'flex';
                    }
                }

                // при наведении мышки на табы, добавляем активный класс
                currentTarget.classList.add('activeTab');
            });
        }

        for (let dropdown of dropdownBlocks) {
            dropdown.addEventListener('mouseleave', () => {
                // удаляем все классы active из блоков контента, если курсор покидает предел выпадающего меню
                for (let content of tabsContent) {
                    content.style.display = 'none';
                }

                // делаем то же самое, но теперь для табов
                for (let tab of tabLinks) {
                    tab.classList.remove('activeTab');
                }
            });
        }
    }

    // END: toggle tabs on hover in dropdown menu


    // open faq item after scroll to this item
    function openFaqAfterScroll() {
        $('.s-package-slider-price-title-link').on('click', function () {
            let sPackageItemID = $(this).attr('href').slice(1);
            $('#faqSection .collapse_custom').children('li').each(function () {
                if ($(this).attr('id') == sPackageItemID && !$(this).hasClass('active')) {
                    $(this).find('.collapsible-header').click()
                }
            })
        })
    }
    openFaqAfterScroll()


    $('.mobile-menu .collapsible').collapsible({

        onOpenEnd: function () {
            let activeElem = $('.mobile-menu .collapsible')
            $('#slide-out').animate({
                scrollTop: activeElem.find('.active').offset().top
            }, 800);
        }
    });

    // get a year
    const putYear = () => {
        let currentDate = new Date()
        let currentYear = currentDate.getFullYear()
        $('.full-year').text(currentYear)
    }
    putYear()

    // change even item bg color (can't use css, because of tablet titles, which ruin sequence - page services/kubernetes/kubernetes-operators-for-development
    function packageSectionEvenBg() {
        $('.s-package-row-item:even').find('.s-package-row-item-text').css("background-color","#F7F7F7");
    }
    packageSectionEvenBg()

    // control s-contact-map
    function controlCollapseImage() {
        $('.s-contact-map-country').on('click', function () {
            // change red dot in list
            $(this).addClass('active').siblings().removeClass('active')


            let countryID = `#${$(this).attr("data-name")}`,
                mainMap = $(".s-contact-map-image svg"),
                mainMapPath = $(".s-contact-map-image svg path"),
                defaultColoredCountries = $(".s-contact-map-image [id]"),
                defaultColoredCountriesWithDataId = $(".s-contact-map-image [data-id]")

            //- clear all default countries
            defaultColoredCountries.each(function () {
                $(this).attr('fill', '#FFDF99').removeClass('active')
            })

            defaultColoredCountriesWithDataId.each(function () {
                $(this).attr('fill', '#FFDF99').removeClass('active')
            })

            //- add active color for selected country
            mainMap.find(countryID).attr('fill', '#FFB000').addClass('active')

            mainMapPath.filter(function (index, element) {
                if ($(element).attr('data-id') === countryID.substr(1)) {
                    $(this).eq(0).attr('fill', '#FFB000').addClass('active');
                }
            });

            $(".s-contact-map-image").find($(`${countryID}-modal`).addClass('active'))
            console.log($(".s-contact-map-image").find($(`${countryID}-modal`)))

        })
    }
    controlCollapseImage()

    // remove #! from search bar when click on link with href="#!"
    function removePlugFromLinks() {
        let linkWithPlug = $('a[href="#!"]')
        linkWithPlug.on('click', function (e) {
            e.preventDefault();

        })
    }
    removePlugFromLinks()

    // Send Data to CRM for hidden input
    let contactForm = document.querySelector('#bottomForm');
    let emailInput = document.querySelector('#email_input');
    let hiddenInput = document.querySelector('.hidden input[name="company"]');

    if (contactForm) {
        if (contactForm.classList.contains('s-contact__form-short')) {
            emailInput.addEventListener("keyup", () => {
                for (let i = 0; i < emailInput.value.length; i++) {
                    if (emailInput.value[i] === "@") {
                        let domainUrl = emailInput.value.slice(i + 1);
                        hiddenInput.defaultValue = domainUrl;
                    }
                }
            });
        }
    }

});


},{}]},{},[1])